import axios from "axios";
import { apiUrl } from "@/env";
import {
  Company,
  CompanyDetail,
  CompanyListItem,
  CompanyLogoDetail,
  DepartmentListItem,
  Warehouse,
  WarehouseCreate,
  WarehouseDetail,
  WarehouseListItem,
  WarehouseUpdate,
} from "@/types/companies";

const client = axios.create({
  baseURL: `${apiUrl}/crud`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
  withCredentials: true,
});

export const companyApi = {
  async getWarehouse(id: Warehouse["id"]) {
    return client.get<WarehouseDetail>(`/warehouses/${id}/`);
  },

  async listWarehouses() {
    return client.get<WarehouseListItem[]>("/warehouses/");
  },

  async listDepartments() {
    return client.get<DepartmentListItem[]>("/departments/");
  },

  async createWarehouse(data: WarehouseCreate) {
    return client.post<{ detail: string }>(`/warehouses/create/`, data);
  },

  async updateWarehouse(id: Warehouse["id"], data: WarehouseUpdate) {
    return client.put<{ detail: string }>(`/warehouses/${id}/update/`, data);
  },

  async deleteWarehouse(id: Warehouse["id"]) {
    return client.delete<{ detail: string }>(`/warehouses/${id}/delete/`);
  },

  async getCompany(id: Company["id"]) {
    return client.get<CompanyDetail>(`/companies/${id}/`);
  },

  async listCompanies() {
    return client.get<CompanyListItem[]>("/companies/");
  },

  async getCompanyLogo(id: Company["id"]) {
    return client.get<CompanyLogoDetail>(`/company-logos/${id}/`);
  },
};
