
  import { Vue, Component, Watch } from "vue-property-decorator";
  import { AppNotification } from "@/types/ui";
  import { uiStore } from "@/store";

  @Component
  export default class NotificationsManager extends Vue {
    public show = false;
    public text = "";
    public showProgress = false;
    public currentNotification: AppNotification | false = false;

    public async hide() {
      this.show = false;
      await new Promise<void>((resolve, _reject) => setTimeout(() => resolve(), 500));
    }

    public async close() {
      await this.hide();
      await this.removeCurrentNotification();
    }

    public async removeCurrentNotification() {
      if (this.currentNotification) {
        uiStore.removeNotification(this.currentNotification);
      }
    }

    public get firstNotification() {
      return uiStore.firstNotification;
    }

    public async setNotification(notification: AppNotification | false) {
      if (this.show) {
        await this.hide();
      }
      if (notification) {
        this.currentNotification = notification;
        this.showProgress = notification.showProgress || false;
        this.show = true;
      } else {
        this.currentNotification = false;
      }
    }

    @Watch("firstNotification")
    public async onNotificationChange(
      newNotification: AppNotification | false,
      _oldNotification: AppNotification | false,
    ) {
      if (newNotification !== this.currentNotification) {
        await this.setNotification(newNotification);
        if (newNotification) {
          uiStore.removeNotificationDelayed({
            notification: newNotification,
            timeout: 6500,
          });
        }
      }
    }

    public get currentNotificationContent() {
      return (this.currentNotification && this.currentNotification.content) || "";
    }

    public get currentNotificationColor() {
      return (this.currentNotification && this.currentNotification.color) || "info";
    }
  }
