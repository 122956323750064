import Vue from "vue";
import VueRouter from "vue-router";

import RouterComponent from "@/components/RouterComponent.vue";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Start",
      component: () => import(/* webpackChunkName: "start" */ "@/views/main/Start.vue"),
      children: [
        {
          path: "login",
          name: "login",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
        },
        {
          path: "recover-password",
          component: () =>
            import(
              /* webpackChunkName: "recover-password" */ "@/views/PasswordRecovery.vue"
            ),
        },
        {
          path: "reset-password",
          component: () =>
            import(
              /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
            ),
        },
        {
          path: "main",
          name: "main",
          component: () =>
            import(/* webpackChunkName: "main" */ "@/views/main/Main.vue"),
          redirect: "main/requisition",
          children: [
            {
              path: "dashboard",
              name: "main-dashboard",
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ "@/views/main/Dashboard.vue"
                ),
            },
            {
              path: "requisition",
              component: RouterComponent,
              redirect: "requisition/list",
              children: [
                {
                  path: "list",
                  name: "main-requisition-list",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-requisition-list" */ "@/views/main/requisition/RequisitionList.vue"
                    ),
                },
                {
                  path: "edit/:id",
                  name: "main-requisition-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-requisition-edit" */ "@/views/main/requisition/EditRequisition.vue"
                    ),
                },
                {
                  path: "create",
                  name: "main-requisition-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-requisition-create" */ "@/views/main/requisition/CreateRequisition.vue"
                    ),
                },
              ],
            },
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "@/views/main/profile/UserProfile.vue"
                    ),
                },
                {
                  path: "edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "@/views/main/profile/UserProfileEdit.vue"
                    ),
                },
                {
                  path: "password",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ "@/views/main/profile/UserProfileEditPassword.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});
