import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { requisitionStore } from "@/store";
import { RequisitionDetail, RequisitionPdfLayout } from "@/types/requisitions";
import { template } from "lodash-es";

function renderTemplate(obj: object, params: object) {
  return JSON.parse(template(JSON.stringify(obj))(params));
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.tableLayouts = {
  firstLineNoVertical: {
    fillColor: (rowIndex: number) => {
      return rowIndex === 0 ? "#CCCCCC" : null;
    },
    hLineWidth: (rowIndex: number) => {
      return rowIndex === 1 ? 1 : 0;
    },
    vLineWidth: (_rowIndex: number) => {
      return 0;
    },
  },
};

type PDF = {
  download: (fileName: string) => void;
  getBase64: (cb: (data: string) => void) => void;
  getBlob: (cb: (blob: Blob) => void) => void;
};

type Node = { id: string };

function breakPageBeforeSignature() {
  return (
    currentNode: Node,
    followingNodesOnPage: Node[],
    _nodesOnNextPage: Node[],
    _previousNodesOnPage: Node[],
  ) => {
    const currentNodeIsSignatureStart = currentNode.id === "signatureTextStart";
    const followingNodeIsSignatureEnd = followingNodesOnPage.some(
      (node) => node.id === "signatureTextEnd",
    );
    return currentNodeIsSignatureStart && !followingNodeIsSignatureEnd;
  };
}

export function createRequisitionPdf(layout: RequisitionPdfLayout) {
  const { header, footer, ...rest } = layout;
  const docDefinition = {
    header: (currentPage: number, pageCount: number, pageSize: number) => {
      return renderTemplate(header, { currentPage, pageCount, pageSize });
    },
    footer: (currentPage: number, pageCount: number) => {
      return renderTemplate(footer, { currentPage, pageCount });
    },
    pageBreakBefore: breakPageBeforeSignature(),
    ...rest,
  };

  return pdfMake.createPdf(docDefinition) as PDF;
}

async function createPdf(requisition: RequisitionDetail) {
  const layout = await requisitionStore.getRequisitionPdfLayout(requisition.id);
  return createRequisitionPdf(layout);
}

export async function createPdfData(requisition: RequisitionDetail) {
  const pdf = await createPdf(requisition);
  const output: string = await new Promise((resolve) => {
    pdf.getBase64((data) => {
      resolve(data);
    });
  });
  return output;
}

export async function downloadPdf(requisition: RequisitionDetail) {
  const pdf = await createPdf(requisition);
  pdf.download(`Materialschein-${requisition.requisition_no}.pdf`);
}
